@import 'styles/theme';

.AlertCenter {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.alert {
  margin-bottom: theme($spacings, 10);
  &:last-child {
    margin-bottom: 0;
  }
}
