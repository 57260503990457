@import 'styles/theme';

.EventPopIn {
  .wrapper {
    z-index: 1000;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include from('md') {
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      height: vh(100);
    }
  }

  .background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;

    @include from('md') {
      display: none;
    }
  }

  .content {
    position: relative;
    background: white;
    display: flex;
    flex-direction: row;
    width: 100%;

    @include from('md') {
      min-height: 50vh;
      justify-content: space-between;

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'columns': 10,
          'gaps': 2,
        )
      );
    }

    @include from('l') {
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'columns': 10,
          'gaps': 0,
        )
      );
    }

    @include from('lg') {
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'columns': 8,
          'gaps': 2,
        )
      );
    }

    @include from('xl') {
      max-width: theme($spacings, 720);

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'columns': 6,
          'gaps': 2,
        )
      );
    }

    .close {
      z-index: 10;
      position: absolute;
      top: theme($spacings, 50);
      right: theme($spacings, 30);
      width: 10px;
      padding: 10px;
      cursor: pointer;

      @include from('md') {
        top: theme($spacings, 20);
        right: theme($spacings, 20);
      }
    }

    .texts {
      padding-top: theme($spacings, 50);
      padding-bottom: theme($spacings, 50);
      padding-left: theme($spacings, 30);
      padding-right: theme($spacings, 30);
      box-sizing: border-box;
      width: 100%;
    }

    .title {
      position: relative;
      padding-left: theme($spacings, 45);

      &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 1px;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        background: theme($colors, 'gold');
      }
    }

    .text {
      margin-top: theme($spacings, 30);
    }

    .cta {
      display: block;
      margin-top: theme($spacings, 50);
    }

    .image {
      position: relative;
      display: none;

      @include from('md') {
        display: block;
        flex-shrink: 0;

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'columns': 5,
            'gaps': 1,
          )
        );
      }

      @include from('l') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'columns': 5,
            'gaps': 0,
          )
        );
      }

      @include from('lg') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'columns': 4,
            'gaps': 1,
          )
        );
      }

      @include from('xl') {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'columns': 3,
            'gaps': 1,
          )
        );
      }

      .ratio {
        height: 100%;
      }
    }
  }
}
