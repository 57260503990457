@import 'styles/theme';

$rowsWidth: 59.03vw;

.Panel {
  display: flex;
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateX(-100%);
  z-index: 2;
}

.wrapper {
  display: flex;
  width: 100%;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: vh(100);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(49, 55, 66, 0.5);
  opacity: 0;
  visibility: hidden;
}

.underline {
  position: sticky;
  top: theme($navigationHeight, 'desktop');
  left: 0;
  right: 0;
  height: 1px;
  background-color: theme($colors, 'gray-93');
  z-index: 2;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -1px;
    height: 1px;
    background-color: theme($colors, 'white');
  }

  &:before {
    left: 0;
    width: theme($desktop, 'gutter');
  }

  &:after {
    right: 0;
    width: theme($desktop, 'gap');
  }
}

.rowsWrapper {
  position: relative;
  height: 100vh;
  background-color: theme($colors, 'white');
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include setPropertyValue(
    (
      'values': $desktop,
      'propertyName': 'width',
      'gutters': 1,
      'gaps': 1,
      'columns': 7,
    )
  );

  @include from('max-grid-width') {
    $base: calc((100vw - #{theme($max, 'width')}) / 2);
    $extras: calc(
      #{theme($desktop, 'gutter')} + #{theme($desktop, 'gap')} + #{getGridColumnMaxWidth(
          7,
          $desktop
        )}
    );
    width: calc(#{$base} + #{$extras});
    max-width: calc(#{$base} + #{$extras});
  }

  .panelBefore {
    position: sticky;
    top: 0;
    width: 100%;
    height: theme($navigationHeight, 'desktop');
    margin-bottom: calc(#{theme($navigationHeight, 'desktop')} * -1);
    background-color: theme($colors, 'white');
    z-index: 1;
  }
}

.rows {
  padding-top: theme($spacings, 150);
  padding-bottom: theme($spacings, 100);
  padding-right: theme($desktop, 'gap');

  @include setPropertyValue(
    (
      'values': $desktop,
      'propertyName': 'padding-left',
      'gutters': 1,
      'gaps': 1,
      'columns': 1,
    )
  );

  box-sizing: border-box;

  @include from('max-grid-width') {
    margin-left: auto;

    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'width',
        'gutters': 1,
        'gaps': 1,
        'columns': 7,
      )
    );
  }
}

.row {
  max-width: 100%;
  margin-left: auto;
  @include setPropertyValue(
    (
      'values': $desktop,
      'propertyName': 'width',
      'gutters': 1,
      'gaps': 1,
      'columns': 7,
    )
  );

  &:not(:last-child) {
    margin-bottom: theme($spacings, 75);
  }
}

.rowImage {
  position: relative;
  flex-grow: 1;
}

.gradiant {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  @include setPropertyValue(
    (
      'values': $desktop,
      'propertyName': 'width',
      'gutters': 1,
      'gaps': 1,
      'columns': 7,
    )
  );
  height: 80px;
}

.chevronIcon {
  position: absolute;
  bottom: theme($spacings, 50);
  @include setPropertyValue(
    (
      'values': $desktop,
      'propertyName': 'left',
      'gutters': 0,
      'gaps': 0.5,
      'columns': 7,
    )
  );
}
