@import 'styles/theme';

.Banner {
  position: relative;
  height: 40px;
  background-color: theme($colors, 'black');
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  overflow-x: hidden;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
    z-index: 100;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, theme($colors, 'black'), transparent);
  }

  &:after {
    right: 0;
    background: linear-gradient(
      to left,
      theme($colors, 'black'),
      rgba(51, 51, 51, 0)
    );
  }

  .text {
    font-size: 12px;
    font-weight: theme($fontWeights, 'medium');
    overflow-x: hidden;
    white-space: nowrap;
    padding: 0 theme($spacings, 20);
  }
}
