@import 'styles/theme';

.PanelManager {
  .container {
    z-index: 20;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;

    &.enterFromLeft {
      align-items: flex-start;

      .content {
        transform: translateX(-100%);
      }
    }
  }

  .background {
    display: none;
    pointer-events: auto;
    opacity: 0;

    @include from('md') {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .content {
    pointer-events: auto;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: theme($colors, 'white');
    transform: translateX(100%);
    overflow: hidden;

    @include from('md') {
      width: theme($spacings, 480);
    }
  }

  .children {
    min-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
