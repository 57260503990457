@import 'styles/theme';

.FooterContact {
  width: 100%;
  padding: theme($spacings, 25) 0 theme($spacings, 50);
  grid-column: 1 / -1;

  @include from('md') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: theme($spacings, 150);
    padding: theme($spacings, 50) 0;
    border-top: solid 1px theme($colors, 'gray-93');
  }

  @include from('l') {
    grid-column: 2 / -2;
  }
}

.contact {
  text-align: center;

  @include from('md') {
    display: flex;
    flex: 1;
    padding-right: theme($spacings, 30);
    text-align: left;
  }
}

.text {
  @include from('md') {
    white-space: nowrap;
  }
}

.platforms {
  margin-top: theme($spacings, 20);

  @include from('md') {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0;
  }
}

.platformWrapper {
  display: flex;
  justify-content: center;

  @include from('md') {
    justify-content: flex-start;
    margin-left: theme($spacings, 50);
  }
}

.platform {
  font-style: italic;
  margin-right: theme($spacings, 5);

  @include from('md') {
    margin-right: theme($spacings, 15);
  }
}

.socials {
  display: flex;
  justify-content: center;
  margin-top: theme($spacings, 50);
  @include from('md') {
    margin-top: 0;
  }
}

.icon {
  transition: ease 0.2s opacity;

  &.isHover {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    margin-right: theme($spacings, 30);
  }

  .image {
    vertical-align: middle;
  }
}
