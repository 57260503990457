@import 'styles/theme';

.Newsletter {
  background-color: theme($colors, 'gray-93');
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.grid {
  position: relative;
}

.images {
  display: none;

  @include from('md') {
    display: block;
  }
}

.topImage,
.leftImage,
.rightImage {
  position: absolute;
}

.topImage {
  top: 0;

  @include columns(
    (
      'mobile': 4,
      'desktop': 4,
    )
  );

  @include from('md') {
    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'left',
        'gutters': 1,
        'gaps': 1,
        'columns': 1,
      )
    );
  }
}

.leftImage {
  @include from('md') {
    left: 0;
    bottom: 0;
    width: calc(
      #{getGridColumnWidth(2, $desktop)} + #{theme($desktop, 'gutter')}
    );
    max-width: calc(
      #{getGridColumnMaxWidth(2, $desktop)} + #{theme($desktop, 'gutter')}
    );
  }

  @include from('max-width') {
    width: calc(
      #{getGridColumnMaxWidth(2, $desktop)} + #{theme($desktop, 'gutter')}
    );
  }
}

.rightImage {
  @include from('md') {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: calc(
      #{getGridColumnWidth(3, $desktop)} + #{theme($desktop, 'gutter')}
    );
    max-width: calc(
      #{getGridColumnMaxWidth(3, $desktop)} + #{theme($desktop, 'gutter')}
    );
  }

  @include from('max-width') {
    width: calc(
      #{getGridColumnMaxWidth(3, $desktop)} + #{theme($desktop, 'gutter')}
    );
  }
}

.content {
  grid-column: 1 / -1;
  background-color: theme($colors, 'white');
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
  margin: theme($spacings, 75) 0;

  @include setPropertyValue(
    (
      'values': $mobile,
      'propertyName': 'padding',
      'gutters': 0,
      'gaps': 1,
      'columns': 1,
    )
  );

  padding: {
    top: theme($spacings, 50);
    bottom: theme($spacings, 50);
  }

  @include from('md') {
    grid-column: 4 / span 6;
    margin: theme($spacings, 155) 0;
    padding: theme($spacings, 75) theme($spacings, 60);
  }

  @include from('lg') {
    grid-column: 5 / span 4;
  }

  .title {
    margin-bottom: theme($spacings, 20);

    @include from('md') {
      margin-bottom: theme($spacings, 30);
    }
  }

  .text {
    margin-bottom: theme($spacings, 30);

    @include from('md') {
      margin-bottom: theme($spacings, 60);
    }
  }

  .socials {
    margin-top: theme($spacings, 50);

    @include from('md') {
      margin-top: theme($spacings, 75);
    }
  }

  .socialsTitle {
    text-transform: uppercase;
    margin-bottom: theme($spacings, 30);
  }

  .icons {
    display: flex;
    justify-content: center;
    margin-top: theme($spacings, 30);

    @include from('md') {
      margin-top: 0;
    }
  }

  .icon {
    position: relative;
    width: theme($spacings, 50);
  }

  .icon:not(:last-child) {
    margin-right: theme($spacings, 20);
  }

  .slider {
    width: 100%;
    margin: 0 auto;
    @include gridColumnWidth(4, $mobile);

    @include from('md') {
      display: none;
    }

    .item {
      @include gridColumnWidth(4, $mobile);
      margin-right: theme($mobile, 'gap');
    }
  }
}

.sliderContainer {
  grid-column: 1 / -1;
  margin: {
    left: calc(#{theme($mobile, 'gutter')} * -1);
    right: calc(#{theme($mobile, 'gutter')} * -1);
  }
}
