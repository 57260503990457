@use 'sass:map';
@import 'styles/theme';

.InlineCta {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.children {
  line-height: inherit;
  position: relative;
  padding-bottom: theme($spacings, 4);
}

.line {
  z-index: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
