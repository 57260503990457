@import 'styles/theme';

.FooterColumn {
  .link {
    display: block;
    text-transform: none;
    transition: ease 0.2s color;

    &:hover {
      color: theme($colors, 'gray-20');
    }

    @include from('md') {
      margin-bottom: theme($spacings, 8);
    }
  }
}
