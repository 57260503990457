@import 'styles/theme';

.Row {
  margin-bottom: theme($spacings, 50);

  &:first-child {
    margin-top: theme($spacings, 30);
  }

  .title {
    display: inline-block;
    font-weight: theme($fontWeights, 'medium');
    margin-bottom: theme($spacings, 16);
    text-transform: none;
  }

  .links {
  }

  .link {
    display: block;
    text-transform: none;
    letter-spacing: 0px;
    margin-bottom: theme($spacings, 5);
  }

  .bottomLink {
    padding-top: 0;
    margin-top: theme($spacings, 30);
  }
}
