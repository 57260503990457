@import 'styles/theme';

$lineWidth: 30px;
$lineSpacings: 20px;

.RowLink {
  list-style: none;
  overflow: hidden;

  .wrapper {
    display: inline-flex;
    align-items: center;
  }

  .link {
    display: block;
  }

  .line {
    position: absolute;
    width: 30px;
    height: 1px;
    margin-right: theme($spacings, 20);
    background-color: theme($colors, 'gold');
    transform: translateX(-100%);
    opacity: 0;
  }
}

:export {
  transformX: $lineWidth + $lineSpacings;
}
