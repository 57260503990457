@import 'styles/theme';

.NewsletterMockInput {
  position: relative;
  padding: 0 theme($spacings, 25) theme($spacings, 9) 0;
  margin-top: theme($spacings, 35);
  text-align: left;
  border-bottom: solid 1px theme($colors, 'gray-93');

  @include from('md') {
    margin-top: theme($spacings, 45);
  }

  .arrowIcon {
    position: absolute;
    top: theme($spacings, 5);
    right: 0;
  }
}
