@import 'styles/theme';

.cta {
  font-family: theme($fontFamilies, 'Graphik');
  font-weight: theme($fontWeights, 'medium');
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 100%;

  &-10 {
    @extend .cta;
    font-size: theme($fontSizes, 10);
    line-height: 20px;
  }

  &-cookies-11 {
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 11);
    line-height: 20px;
  }

  &-form-11 {
    @extend .cta;
    font-size: theme($fontSizes, 11);
    line-height: 1.82;
    letter-spacing: normal;
    text-transform: none;
    font-weight: theme($fontWeights, 'regular');
  }

  &-11 {
    @extend .cta;
    letter-spacing: 2px;
    font-size: theme($fontSizes, 11);
  }

  &-11-13 {
    @extend .cta;
    letter-spacing: normal;
    font-size: theme($fontSizes, 11);
    font-weight: theme($fontWeights, 'regular');

    @include from('md') {
      font-size: theme($fontSizes, 13);
    }
  }

  &-12 {
    @extend .cta;
    font-size: theme($fontSizes, 12);
  }

  &-13 {
    @extend .cta;
    font-size: theme($fontSizes, 13);
    letter-spacing: 0.4px;
  }

  &-order-13 {
    @extend .cta;
    font-size: theme($fontSizes, 12);
    letter-spacing: normal;
    text-transform: none;
    font-weight: theme($fontWeights, 'regular');

    @include from('l') {
      font-size: theme($fontSizes, 13);
    }
  }

  &-14 {
    @extend .cta;
    font-family: theme($fontFamilies, 'GTSuperText');
    font-size: theme($fontSizes, 14);
    letter-spacing: inherit;
    line-height: 1.67;
  }

  &-14-12 {
    @extend .cta;
    font-family: theme($fontFamilies, 'GTSuperText');
    font-size: theme($fontSizes, 12);
    letter-spacing: inherit;
    line-height: 1.67;

    @include from('md') {
      font-size: theme($fontSizes, 14);
    }
  }

  &-GT-18 {
    @extend .cta;
    font-family: theme($fontFamilies, 'GTSuperText');
    font-size: theme($spacings, 18);
    line-height: 1.67;
    text-transform: none;
  }

  &-GT-18-16 {
    @extend .cta;
    @extend .cta-GT-18;
    font-size: theme($spacings, 16);
    letter-spacing: normal;

    @include from('md') {
      font-size: theme($spacings, 18);
    }
  }

  &-breadcrumb {
    font-family: theme($fontFamilies, 'GTSuperText');
    font-size: theme($fontSizes, 12);
    color: theme($colors, 'gray-47');
    text-transform: none;
    letter-spacing: 0;
  }
}
