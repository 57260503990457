@import 'styles/theme';

.DesktopHeader {
  position: relative;
  z-index: 10;

  .container {
    width: 100%;
    max-width: none;
    will-change: transform;
    position: relative;
    padding: 0;

    @include from('max-grid-width') {
      width: 100%;
      max-width: none;
    }
  }
}

.underline {
  position: absolute;
  left: theme($desktop, 'gutter');
  right: theme($desktop, 'gutter');
  bottom: 0px;
  height: 1px;
  background-color: theme($colors, 'gray-20');
  opacity: 0.1;
  transition: ease 0.15s opacity;
}

.headerLeft {
  grid-column: 1 / span 5;
  padding-left: theme($desktop, 'gutter');
}

.headerRight {
  justify-content: flex-end;
  grid-column: 8 / -1;

  .link.iconLink {
    cursor: pointer;
    width: theme($spacings, 14);
    padding: 0;
    transition: ease 0.2s opacity;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;

    &.isHover {
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }

  .cartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    width: theme($spacings, 16);
    height: theme($spacings, 16);
    position: absolute;
    transform: translate(50%, -50%);
    font-weight: theme($fontWeights, 'medium');
  }
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: center;

  .link {
    &.langSwitcher {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: solid 1px rgba(51, 51, 51, 0.1);
    }

    &:not(:first-child) {
      margin-left: theme($spacings, 20);

      @include from('xl') {
        margin-left: theme($spacings, 30);
      }
    }
  }

  .inlineCta {
    display: block;
    line-height: 1.82;
    padding-bottom: theme($spacings, 20);
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 6 / span 2;

  .logo {
    padding: 0;
  }
}
