@import 'styles/theme';

.Accordion {
  width: 100%;
  overflow: hidden;
}

.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.link {
  text-transform: uppercase;
  text-align: left;
}

.subPane {
  height: 0;
}

.arrow {
  transform-origin: 45% 45%;
  transition: ease-in-out 0.2s transform;
  &.isOpen {
    transform: rotate(90deg);
  }
}

.dark {
  background-color: red;
  .arrow {
    background-color: blue;
    path {
      stroke: theme($colors, 'black');
    }
  }
}
