@import 'styles/theme';

.header {
  width: 100%;
  z-index: 10;
  top: 0;
  position: fixed;
  pointer-events: none;
}

.wrapper {
  position: relative;
}

.background {
  position: fixed;
  width: 100%;
  height: theme($navigationHeight, 'mobile');
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: theme($colors, 'white');
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s linear;

  @include from('lg') {
    display: none;
  }

  &.headerLight,
  &.hasScrolled {
    opacity: 1;
    visibility: visible;
  }
}

.navigation {
  position: relative;
  z-index: 30;
  pointer-events: all;
}

.scrollRef {
  visibility: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
  height: var(--banner-height);
}

.beforePanel {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - #{theme($mobile, 'gutter')} * 2);
  height: 100%;
  z-index: 21;
  background-color: theme($colors, 'gray-96');
  border-bottom: solid 1px theme($colors, 'gray-93');

  @include from('md') {
    width: calc(100% - #{theme($desktop, 'gutter')} * 2);
  }
}

.mobilePanel {
  pointer-events: all;
  @include from('lg') {
    display: none;
  }
}

.mobileContentPanel {
  padding-top: var(--panel-padding-top);
}

.largeScreenNavigation {
  display: none;

  @include from('lg') {
    display: block;
    top: 0;
    z-index: 10;
  }
}

.smallScreenNavigation {
  display: block;
  top: 0;
  z-index: 10;

  @include from('lg') {
    display: none;
  }
}

:export {
  mobileHeaderHeight: theme($navigationHeight, 'mobile');
}
