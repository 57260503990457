@import 'styles/theme';

.Button {
  width: 100%;
  padding: theme($spacings, 20);
  background-color: theme($colors, 'white');
  box-sizing: border-box;
  text-align: center;

  &:first-child {
    border-right: solid 1px theme($colors, 'gray-96');
  }
}
