@import 'styles/theme';

.BottomButtons {
  display: flex;
  width: 100%;
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 1;
}
