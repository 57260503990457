@use 'sass:math';
@import './mixins';

/*
  COLORS
*/
$colors: (
  'black': #333333,
  'dark': #333333,
  'light': #ffffff,
  'white': #ffffff,
  'gray-20': #333333,
  'gray-27': #444444,
  'gray-31': #4f4f4f,
  'gray-47': #777777,
  'gray-51': #828282,
  'gray-67': #aaaaaa,
  'gray-68': #aeaeae,
  'gray-74': #bdbdbd,
  'gray-84': #d6d6d6,
  'gray-88': #e0e0e0,
  'gray-93': #eeeeee,
  'gray-96': #f6f6f6,
  'gray-98': #f9f9f9,
  'red': #ff0000,
  'gold': #c1976d,
);

/*
  GRID
*/
$mobile: (
  'columns': 6,
  'gap': min(15px, 3.44vw),
  'gutter': min(30px, 8vw),
);

$desktop: (
  'columns': 12,
  'gap': clamp(30px, 1.95vw, 40.4166px),
  'gutter': clamp(45px, 3.125vw, 60.625px),
);

$max: (
  'width-design': 1440px,
  'width-scale': 1790px,
  'width': 1940px,
);

/*
  FONTS
*/
$Graphik: 'Graphik', sans-serif;
$GTSuperText: 'GTSuperText', sans-serif;

$fontFamilies: (
  Graphik: $Graphik,
  GTSuperText: $GTSuperText,
);

$fontSizes: (
  8: getClampedValue(8px),
  9: getClampedValue(9px),
  10: getClampedValue(10px),
  11: getClampedValue(11px),
  12: getClampedValue(12px),
  13: getClampedValue(13px),
  14: getClampedValue(14px),
  15: getClampedValue(15px),
  16: getClampedValue(16px),
  18: getClampedValue(18px),
  20: getClampedValue(20px),
  30: getClampedValue(30px),
  36: getClampedValue(36px),
);

$fluidFontSizes: (
  16: 16px,
  20: 20px,
  24: 24px,
  30: 30px,
  36: 36px,
);

$fontWeights: (
  'book': 300,
  'regular': 400,
  'medium': 500,
  'semibold': 600,
);

// MARGIN AND PADDING
$spacings: (
  1: getClampedValue(1px),
  2: getClampedValue(2px),
  3: getClampedValue(3px),
  4: getClampedValue(4px),
  5: getClampedValue(5px),
  6: getClampedValue(6px),
  7: getClampedValue(7px),
  8: getClampedValue(8px),
  9: getClampedValue(9px),
  10: getClampedValue(10px),
  12: getClampedValue(12px),
  14: getClampedValue(14px),
  15: getClampedValue(15px),
  16: getClampedValue(16px),
  17: getClampedValue(17px),
  18: getClampedValue(18px),
  19: getClampedValue(19px),
  20: getClampedValue(20px),
  24: getClampedValue(24px),
  25: getClampedValue(25px),
  28: getClampedValue(28px),
  30: getClampedValue(30px),
  32: getClampedValue(32px),
  35: getClampedValue(35px),
  40: getClampedValue(40px),
  45: getClampedValue(45px),
  50: getClampedValue(50px),
  55: getClampedValue(55px),
  60: getClampedValue(60px),
  63: getClampedValue(63px),
  65: getClampedValue(65px),
  70: getClampedValue(70px),
  75: getClampedValue(75px),
  80: getClampedValue(80px),
  90: getClampedValue(90px),
  95: getClampedValue(95px),
  100: getClampedValue(100px),
  106: getClampedValue(106px),
  112: getClampedValue(112px),
  115: getClampedValue(115px),
  120: getClampedValue(120px),
  150: getClampedValue(150px),
  155: getClampedValue(155px),
  160: getClampedValue(160px),
  165: getClampedValue(165px),
  185: getClampedValue(185px),
  195: getClampedValue(195px),
  200: getClampedValue(200px),
  210: getClampedValue(210px),
  250: getClampedValue(250px),
  270: getClampedValue(270px),
  275: getClampedValue(275px),
  280: getClampedValue(280px),
  300: getClampedValue(300px),
  480: getClampedValue(480px),
  720: getClampedValue(720px),
);

/*
  SCREENS
*/
$screens: (
  xxxs: 375px,
  xxs: 480px,
  xs: 576px,
  sm: 640px,
  md: 768px,
  l: 930px,
  lg: 1024px,
  xl: 1280px,
  xxl: map-get($max, 'width-design'),
  'max-scale-width': map-get($max, 'width-scale'),
  'max-grid-width': map-get($max, 'width'),
);

/*
  RATIO
*/
$ratio: (
  '18/25': math.div(18, 25),
  '2/3': math.div(2, 3),
  '179/227': math.div(179, 227),
  '388/227': math.div(388, 227),
  '570/227': math.div(570, 227),
  '180/209': math.div(180, 209),
  '200/250': math.div(200, 250),
  '375/500': math.div(375, 500),
  '375/280': math.div(375, 280),
  '1440/860': math.div(1440, 860),
  '1440/360': math.div(1440, 360),
);

$navigationHeight: (
  'mobile': getClampedValue(55px),
  'desktop': getClampedValue(59px),
);
