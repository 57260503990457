@import 'styles/theme';

.paddings {
  box-sizing: border-box;
  padding: {
    left: theme($mobile, 'gutter');
    right: theme($mobile, 'gutter');
    top: theme($spacings, 15);
    bottom: theme($spacings, 12);
  }

  @include from('lg') {
    padding: {
      left: theme($spacings, 30);
      right: theme($spacings, 30);
      top: theme($spacings, 10);
      bottom: theme($spacings, 10);
    }
  }
}

.DownshiftSelect {
  width: 100%;
  position: relative;

  .icon {
    line-height: 0;
    width: 10px;
    height: 6px;
    transform: rotate(0deg);
  }

  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    @extend .paddings;

    span {
      margin-right: theme($spacings, 15);
    }
  }

  &.productSelect,
  &.dateSelect {
    border-top: solid 1px theme($colors, 'gray-20');
  }

  &.productSelect {
    .button {
      padding: {
        left: theme($spacings, 15);
        right: theme($spacings, 15);
        top: theme($spacings, 15);
        bottom: theme($spacings, 10);
      }
    }
  }

  &.langSelect {
    background-color: theme($colors, 'white');

    @include from('lg') {
      background-color: transparent;
    }

    .button {
      @include from('lg') {
        padding-right: theme($desktop, 'gutter');
      }
    }
  }

  &.dateSelect {
    .icon {
      right: theme($spacings, 15);
      top: calc(50% - #{theme($spacings, 3)});
    }

    .button {
      padding: {
        left: theme($spacings, 15);
        right: theme($spacings, 15);
        top: theme($spacings, 14);
        bottom: theme($spacings, 10);
      }

      @include from('md') {
        padding: {
          left: theme($spacings, 15);
          right: theme($spacings, 15);
          top: theme($spacings, 14);
          bottom: theme($spacings, 10);
        }
      }
    }
  }
}
