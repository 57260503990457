@import 'styles/theme';

.LangSwitcher {
  display: flex;
  position: relative;
  margin: theme($spacings, 35) auto theme($spacings, 100);

  @include from('lg') {
    height: 100%;
    margin: 0;
  }

  .select {
    width: 100%;
    height: 100%;
  }
}
