@import 'styles/theme';

.Reassurance {
  margin-top: theme($spacings, 75);
  margin-bottom: theme($spacings, 75);

  @include from('l') {
    margin-top: theme($spacings, 250);
    margin-bottom: theme($spacings, 200);
  }

  .grid {
    &.withSlider {
      display: none;

      @include from('l') {
        display: grid;
      }
    }
  }

  .gridContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-column: 1 / -1;
    margin-top: theme($spacings, 75);

    &:first-of-type {
      margin-top: 0;
    }

    @include from('md') {
      grid-column: 2 / 12;
    }

    @include from('l') {
      align-items: stretch;
      flex-direction: row;
    }
  }

  .item {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: theme($spacings, 75);

    &.withSlider {
      width: 100vw;
      margin-top: 0;

      @include from('l') {
        width: calc(100% / 3);
      }
    }

    @include from('l') {
      width: calc(100% / 3);
      margin-top: 0;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &.withSeparator {
      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 80px;
        transform: translateY(-50%);
        background: theme($colors, 'gray-88');

        @include from('l') {
          content: '';
        }
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }

    .content {
      max-width: 69vw;
      margin: 0 auto;

      @include from('l') {
        max-width: 18vw;
      }
    }

    .title {
      margin-top: theme($spacings, 20);

      @include from('l') {
        margin-top: theme($spacings, 50);
      }
    }

    .text {
      margin-top: theme($spacings, 10);
    }

    .cta {
      display: inline-block;
      text-transform: none;
      letter-spacing: inherit;
      font-weight: theme($fontWeights, 'regular');
      margin-top: theme($spacings, 10);

      @include from('l') {
        margin-top: theme($spacings, 20);
      }
    }
  }

  .slider {
    width: 100%;

    @include from('l') {
      display: none;
    }
  }

  .sliderControls {
    justify-content: center;
    margin-top: theme($spacings, 50);

    @include from('l') {
      display: none;
    }
  }
}
