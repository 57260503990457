@import 'styles/theme';

@keyframes loaderAnimation {
  0% {
    transform-origin: left center;
    transform: scale3d(0, 1, 1);
  }

  10% {
    transform-origin: left center;
    transform: scale3d(0, 1, 1);
  }

  45% {
    transform-origin: left center;
    transform: scale3d(1, 1, 1);
  }

  55% {
    transform-origin: right center;
    transform: scale3d(1, 1, 1);
  }

  90% {
    transform-origin: right center;
    transform: scale3d(0, 1, 1);
  }

  100% {
    transform-origin: right center;
    transform: scale3d(0, 1, 1);
  }
}

.Loader {
  position: relative;
  width: 30px;
  height: 1px;
  background: theme($colors, 'gray-93');

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: theme($colors, 'gray-20');
    animation-duration: 1s;
    animation-name: loaderAnimation;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.515, 0.275, 0.715, 0.495);
  }
}
