@import 'styles/theme';

.input {
  font-family: theme($fontFamilies, 'GTSuperText');
  font-weight: theme($fontWeights, 'regular');
  line-height: 1.43;
  font-size: theme($fontSizes, 16);

  &-label-10 {
    @extend .input;
    color: theme($colors, 'gray-47');
    font-size: theme($fontSizes, 10);

    @include from('md') {
      font-size: theme($fontSizes, 10);
    }
  }

  &-14 {
    @extend .input;

    @include from('md') {
      font-size: theme($fontSizes, 14);
    }
  }

  &-14-12 {
    @extend .input;
    // font-size: theme($fontSizes, 12);

    @include from('md') {
      font-size: theme($fontSizes, 14);
    }
  }

  &-select-10 {
    @extend .input;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'medium');
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2px;
    font-size: theme($fontSizes, 10);

    @include from('md') {
      font-size: theme($fontSizes, 10);
    }
  }
}
