@import 'styles/theme';

.text {
  font-family: theme($fontFamilies, 'GTSuperText');
  font-weight: theme($fontWeights, 'regular');
  line-height: 1.9;

  &-9-11 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 9);
    line-height: 1.44;

    @include from('md') {
      font-size: theme($fontSizes, 11);
      line-height: 1.36;
    }
  }

  &-10-11 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 10);
    line-height: 1.3;

    @include from('md') {
      font-size: theme($fontSizes, 11);
      line-height: 1.87;
    }
  }

  &-8 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 8);
  }

  &-10 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 10);
    letter-spacing: 3px;
  }

  &-11 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 11);
    line-height: 1.82;
  }

  &-12 {
    @extend .text;
    font-size: theme($fontSizes, 12);
  }

  &-13 {
    @extend .text;
    font-size: theme($fontSizes, 13);
  }

  &-10-12 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 10);

    @include from('md') {
      font-size: theme($fontSizes, 12);
    }
  }
  &-13-14 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 13);

    @include from('md') {
      font-size: theme($fontSizes, 14);
    }
  }

  &-12-16 {
    @extend .text;
    font-size: theme($fontSizes, 12);

    @include from('md') {
      font-size: theme($fontSizes, 16);
    }
  }

  &-12-14 {
    @extend .text;
    font-size: theme($fontSizes, 12);
    line-height: 1.67;

    @include from('md') {
      line-height: 1.79;
      font-size: theme($fontSizes, 14);
    }
  }

  &-13-18 {
    @extend .text;
    font-size: theme($fontSizes, 13);

    @include from('md') {
      font-size: theme($fontSizes, 18);
    }
  }

  &-14 {
    @extend .text;
    font-size: theme($fontSizes, 14);
  }

  &-Graphik-15 {
    @extend .text;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 15);
  }

  &-16 {
    @extend .text;
    font-size: theme($fontSizes, 16);
  }

  &-16-13 {
    @extend .text;
    font-size: theme($fontSizes, 13);

    @include from('md') {
      font-size: theme($fontSizes, 16);
    }
  }

  &-16-18 {
    @extend .text;
    font-size: theme($fontSizes, 16);

    @include from('md') {
      font-size: theme($fontSizes, 18);
    }
  }

  &-18 {
    @extend .text;
    font-size: theme($fontSizes, 18);
  }

  &-20-30 {
    @extend .text;
    font-size: theme($fontSizes, 20);

    @include from('md') {
      font-size: theme($fontSizes, 30);
    }
  }

  &-fluid-20-36 {
    @extend .text;
    font-size: theme($fontSizes, 20);
    line-height: 30px;

    @include from('md') {
      line-height: 1.65;

      @include fluidType(
        theme($screens, 'md'),
        theme($max, 'width-design'),
        theme($fluidFontSizes, 20),
        theme($fluidFontSizes, 36)
      );
    }

    @include scaledFontSize(theme($fluidFontSizes, 36));
  }
}
