@import 'styles/theme';

.card {
  font-family: theme($fontFamilies, 'GTSuperText');
  font-weight: theme($fontWeights, 'regular');
  letter-spacing: 0;

  &-11-13 {
    font-family: theme($fontFamilies, 'Graphik');
    font-weight: theme($fontWeights, 'regular');
    font-size: theme($fontSizes, 11);
    line-height: 1.92;

    @include from('md') {
      font-size: theme($fontSizes, 13);
    }
  }

  &-12-14 {
    @extend .card;
    font-size: theme($fontSizes, 12);
    line-height: 1.428;

    @include from('md') {
      font-size: theme($fontSizes, 14);
    }
  }
}
