@import 'styles/theme';

.Slice404 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: theme($spacings, 100);
  padding-bottom: theme($spacings, 100);
  box-sizing: border-box;

  @include from('md') {
    min-height: stableVh(100);
    padding-top: theme($navigationHeight, 'mobile');
    padding-bottom: 0;
  }

  @include from('lg') {
    padding-top: theme($navigationHeight, 'desktop');
  }

  .image {
    position: relative;
    grid-column: 1 / 4;

    @include from('md') {
      grid-column: 2 / 6;
    }

    @include from('lg') {
      grid-column: 3 / 7;
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1 / -1;
    margin-top: theme($spacings, 50);

    @include from('md') {
      margin-top: 0;
      grid-column: 7 / 12;
    }

    @include from('lg') {
      grid-column: 8 / 12;
    }
  }

  .textsContent {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 1px;
      background: theme($colors, 'gold');
    }
  }

  .title {
    margin-top: theme($spacings, 30);
  }

  .cta {
    display: block;
    margin-top: theme($spacings, 30);
  }
}
