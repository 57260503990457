@import 'styles/theme';

.Line {
  display: block;
  height: 1px;
  width: 100%;
  overflow: hidden;

  .lineProgress {
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: theme($colors, 'black');
    transform-origin: center left;
    transform: scaleX(0);
    z-index: 2;
  }

  &::after {
    z-index: 1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    display: none;
  }

  &.isUnderlined {
    &::after {
      display: block;
    }

    &.theme-gray {
      .lineProgress {
        background-color: theme($colors, 'black');
      }

      &:after {
        background-color: theme($colors, 'gray-68');
      }
    }

    &.theme-black {
      .lineProgress {
        background-color: theme($colors, 'gray-68');
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &.theme-inherit {
    .lineProgress {
      background-color: currentColor;
    }

    &::after {
      background-color: currentColor;
    }
  }

  &.theme-gold {
    .lineProgress {
      background-color: theme($colors, 'gold');
    }

    &:after {
      background-color: theme($colors, 'gold');
    }
  }

  &.theme-black {
    .lineProgress {
      background-color: theme($colors, 'black');
    }

    &:after {
      background-color: theme($colors, 'black');
    }
  }

  &.theme-gray {
    .lineProgress {
      background-color: theme($colors, 'gray-68');
    }

    &:after {
      background-color: theme($colors, 'black');
      opacity: 1;
    }
  }
}
