@import 'styles/theme';

.NavigationLink {
  display: block;

  &.menuAccordion {
    line-height: 2.5;
    padding-block: theme($spacings, 15);
  }

  &.primary {
    line-height: 1.82;
    padding: theme($spacings, 20) 0 0;
  }

  &.secondary {
    padding: theme($spacings, 42) 0;
  }

  &.dropdown {
    margin-bottom: theme($spacings, 6);

    @include from('lg') {
      margin-bottom: theme($spacings, 4);
    }
  }
}
