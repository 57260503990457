@import 'styles/theme';

.PanelLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .spinner {
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
