@import 'styles/theme';

$headerHeight: theme($navigationHeight, 'mobile');

.Navigation {
  position: relative;
  z-index: 99;
  width: 100%;
}

:export {
  headerHeight: $headerHeight;
}
