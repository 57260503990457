@import 'styles/theme';

.Row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .title {
    text-align: left;
    font-weight: theme($fontWeights, 'medium');
    text-transform: none;
    letter-spacing: 0.5px;
    cursor: initial;

    &.withHref {
      cursor: pointer;
    }

    @include columns(
      (
        mobile: 6,
        desktop: 2,
      )
    );
  }

  .links {
    @include columns(
      (
        mobile: 6,
        desktop: 4,
      )
    );
  }

  .title,
  .link {
    line-height: 30px;
  }

  .link {
    display: block;
    text-transform: none;
    letter-spacing: 0;
  }

  .bottomLink {
    display: inline-block;
    margin-top: theme($spacings, 30);

    @include from('lg') {
      margin-top: theme($spacings, 20);
    }
  }
}
