@import 'styles/theme';

.DesktopPanelImage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: none;

  @include from('lg') {
    display: block;
  }

  .image {
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 1;

    @include setPropertyValue(
      (
        'values': $desktop,
        'propertyName': 'width',
        'gutters': 1,
        'gaps': 1,
        'columns': 5,
      )
    );

    @include from('max-grid-width') {
      $base: calc((100vw - #{theme($max, 'width')}) / 2);
      $extras: calc(
        #{theme($desktop, 'gutter')} + #{theme($desktop, 'gap')} + #{getGridColumnMaxWidth(
            5,
            $desktop
          )}
      );
      width: calc(#{$base} + #{$extras});
      max-width: calc(#{$base} + #{$extras});
    }
  }
}
