@import './reset.scss';
@import './theme';

:root {
  --scroll-bar: 0px;
  --scroll-bar-grid: 0px;
  --vh100: 100vh;
  --panel-padding-top: 115px;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Medium.woff2') format('woff2'),
    url('/fonts/Graphik/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-RegularItalic.woff2') format('woff2'),
    url('/fonts/Graphik/Graphik-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-SemiboldItalic.woff2') format('woff2'),
    url('/fonts/Graphik/Graphik-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Regular.woff2') format('woff2'),
    url('/fonts/Graphik/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-MediumItalic.woff2') format('woff2'),
    url('/fonts/Graphik/Graphik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Semibold.woff2') format('woff2'),
    url('/fonts/Graphik/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GTSuperText';
  src: url('/fonts/GTSuperText/GTSuperText-BookItalic.woff2') format('woff2'),
    url('/fonts/GTSuperText/GTSuperText-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GTSuperText';
  src: url('/fonts/GTSuperText/GTSuperText-Book.woff2') format('woff2'),
    url('/fonts/GTSuperText/GTSuperText-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  color: theme($colors, 'black');
  font-family: theme($fontFamilies, 'GTSuperText');
  font-weight: theme($fontWeights, 'regular');
  font-size: theme($fontSizes, 16);
  line-height: 1.4;
  overscroll-behavior-y: none;
}

body {
  min-height: stableVh(100);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:focus {
  outline: none;
}

em {
  font-style: italic;
}

.grecaptcha-badge {
  visibility: hidden;
}

.sb-show-main.sb-main-centered #root {
  padding: 0;
  box-sizing: border-box;
}

body {
  &.loading {
    cursor: wait;
  }
}

strong {
  font-weight: bold;
}

// * {
//   outline: 2px dotted red;
// }
// * * {
//   outline: 2px dotted green;
// }
// * * * {
//   outline: 2px dotted orange;
// }
// * * * * {
//   outline: 2px dotted blue;
// }
// * * * * * {
//   outline: 1px solid red;
// }
// * * * * * * {
//   outline: 1px solid green;
// }
// * * * * * * * {
//   outline: 1px solid orange;
// }
// * * * * * * * * {
//   outline: 1px solid blue;
// }
