@import 'styles/theme';

.AccordionPanel {
  overflow: hidden;
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.arrowWrapper {
  flex: 1;
  text-align: right;
}

.arrow {
  transform-origin: 45% 45%;
  transition: ease-in-out 0.2s transform;
  &.isOpen {
    transform: rotate(90deg);
  }
  &.dark {
    transform: rotate(-180deg);
    &.isOpen {
      transform: rotate(0);
    }
  }
}

.body {
  height: 0;
  overflow: hidden;
}
