@import 'styles/theme';

.PanelWrapper {
  z-index: 20;
  width: 100%;
  height: 100%;

  .close {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: max(40px, min(80px, 10vh));
    cursor: pointer;
    z-index: 1;

    @include from('md') {
      display: none;
    }

    svg {
      width: theme($spacings, 60);
      height: theme($spacings, 60);
    }
  }

  .childrenWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: calc(#{vh(100)} - #{theme($spacings, 50)});
    padding-top: theme($spacings, 50);

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      top: theme($spacings, 50);
      left: theme($mobile, 'gutter');
      right: theme($mobile, 'gutter');
      background-color: theme($colors, 'gray-68');
    }

    @include from('md') {
      height: calc(#{vh(100)} - #{theme($navigationHeight, 'mobile')});
      padding-top: theme($navigationHeight, 'mobile');

      &:before {
        top: theme($navigationHeight, 'mobile');
        left: theme($spacings, 60);
        right: theme($spacings, 60);
      }
    }

    @include from('lg') {
      height: calc(#{vh(100)} - #{theme($navigationHeight, 'desktop')});
      padding-top: theme($navigationHeight, 'desktop');

      &:before {
        top: theme($navigationHeight, 'desktop');
      }
    }

    &.withoutBorder {
      padding-top: 0;

      &::before {
        content: none;
      }

      .childrenContent {
        padding-top: theme($spacings, 50);

        @include from('md') {
          padding-top: theme($navigationHeight, 'mobile');
        }

        @include from('lg') {
          padding-top: theme($navigationHeight, 'desktop');
        }
      }
    }

    .childrenContent {
      min-height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .children {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      padding: 0 theme($mobile, 'gutter') max(80px, min(160px, 20vh));
      box-sizing: border-box;

      &.verticalAlignCenter {
        justify-content: center;
      }

      @include from('md') {
        padding: 0 theme($spacings, 60) theme($spacings, 30);
      }
    }
  }
}
