@import 'styles/theme';

.Footer {
  padding-top: theme($spacings, 50);
  background-color: theme($colors, 'gray-98');
  position: relative;
  z-index: 1;

  @include from('md') {
    padding-top: theme($spacings, 150);
  }
}

.grid {
  grid-row-gap: theme($spacings, 50);

  @include from('md') {
    grid-row-gap: 0;
  }
}

.column {
  grid-column: 1 / span 3;

  &:nth-child(2n) {
    grid-column: 4 / -1;
  }

  @include from('md') {
    grid-column: 1 / span 3;

    &:nth-child(2n) {
      grid-column: 4 / span 3;
    }
  }

  @include from('l') {
    grid-column: 2 / span 3;

    &:nth-child(2n) {
      grid-column: 5 / span 3;
    }
  }
}

.newsletter {
  grid-column: 1 / -1;
  padding-top: theme($spacings, 30);
  border-top: solid 1px theme($colors, 'gray-93');

  @include from('md') {
    padding-top: 0;
    grid-column: 8 / -1;
    border: none;
  }

  @include from('l') {
    grid-column: 8 / -2;
  }

  .newsletterText {
    padding-bottom: theme($spacings, 10);
    line-height: 1.67;

    @include from('md') {
      padding-bottom: theme($spacings, 25);
    }
  }
}

.title {
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: theme($spacings, 20);

  @include from('md') {
    margin-bottom: theme($spacings, 30);
  }
}

.recaptcha {
  grid-column: 1 / -1;
  text-align: center;
  padding-top: theme($spacings, 10);
  padding-bottom: theme($spacings, 10);
}
