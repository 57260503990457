@import 'styles/theme';

.MobileHeader {
  // width: vw(100);
  position: relative;
  padding: {
    top: theme($spacings, 17);
    bottom: theme($spacings, 16);
  }
  z-index: 21;

  &.hasScrolled {
    &:after {
      opacity: 0;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: theme($mobile, 'gutter');
    right: theme($mobile, 'gutter');
    bottom: -1px;
    height: 1px;
    background-color: theme($colors, 'gray-20');
    opacity: 0.1;
    transition: ease 0.15s opacity;

    @include from('md') {
      left: theme($desktop, 'gutter');
      right: theme($desktop, 'gutter');
    }
  }

  .cartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    width: theme($spacings, 16);
    height: theme($spacings, 16);
    position: absolute;
    transform: translate(50%, -50%);
    font-weight: theme($fontWeights, 'medium');
  }
}

.container {
  will-change: transform;
  position: relative;
}

.headerLeft {
  grid-column: 1 / span 2;

  @include from('md') {
    grid-column: 1 / span 5;
  }
}

.headerRight {
  justify-content: flex-end;
  grid-column: -3 / -1;
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: center;

  .link.iconLink {
    width: 16px;
    padding: 0;
  }

  .link {
    &:not(:first-child) {
      margin-left: theme($spacings, 30);
    }
  }

  .inlineCta {
    padding-bottom: theme($spacings, 20);
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3 / span 2;

  @include from('md') {
    grid-column: 6 / span 2;
  }

  .logo {
    padding: 0;
  }
}

.menuIcon {
  overflow: hidden;
  cursor: pointer;

  svg {
    pointer-events: none;
  }
}
