@import 'styles/theme';

.ImageWithPlaceHolder {
  background-color: theme($colors, 'gray-98');

  .imageUnavailable {
    padding: theme($spacings, 12) theme($spacings, 10);
    box-sizing: border-box;
    border-radius: 3px;
    background-color: theme($colors, 'gray-93');
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      display: block;
      font-weight: theme($fontWeights, 'regular');
      letter-spacing: 0px;
      line-height: 0;
      text-transform: none;
      white-space: nowrap;
    }
  }

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: theme($colors, 'gray-98');
    transition: ease 0.25s opacity;

    &.loaded {
      opacity: 0;
    }

    .loader {
      &.loaded {
        opacity: 0;
      }
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
