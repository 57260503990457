@import 'styles/theme';

.Page {
  position: relative;

  > * {
    &:first-child {
      margin-top: 0;
    }
  }
}

:export {
  maxGridWidth: theme($max, 'width');
}
