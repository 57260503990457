@import 'styles/theme';

.ratio-18-25 {
  @include genPaddingBottomFromRatio(theme($ratio, '18/25'));
}

.ratio-2-3 {
  @include genPaddingBottomFromRatio(theme($ratio, '2/3'));
}

.ratio-179-227 {
  @include genPaddingBottomFromRatio(theme($ratio, '179/227'));
}

.ratio-388-227 {
  @include genPaddingBottomFromRatio(theme($ratio, '388/227'));
}

.ratio-570-227 {
  @include genPaddingBottomFromRatio(theme($ratio, '570/227'));
}

.ratio-180-209 {
  padding-bottom: 100vh;

  @include from('md') {
    @include genPaddingBottomFromRatio(theme($ratio, '180/209'));
  }
}

.ratio-1440-860-375-500 {
  @include genPaddingBottomFromRatio(theme($ratio, '375/500'));

  @include from('md') {
    @include genPaddingBottomFromRatio(theme($ratio, '1440/860'));
  }
}

.ratio-1440-360-375-280 {
  @include genPaddingBottomFromRatio(theme($ratio, '375/280'));

  @include from('md') {
    @include genPaddingBottomFromRatio(theme($ratio, '1440/360'));
  }
}
