@import 'styles/theme';

.SliderControls {
  display: flex;

  .buttonsWrapper {
    display: flex;
  }

  .button {
    cursor: pointer;
    width: 5px;
    height: 5px;
    background-color: theme($colors, 'gray-20');
    opacity: 0.2;
    transition: ease 0.1s background-color;

    &.isActive {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: theme($spacings, 10);
    }
  }
}
