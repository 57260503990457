@import 'styles/theme';

.spinner {
  animation: rotate 2s linear infinite;
  width: 100%;

  .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    stroke: theme($colors, 'gray-68');

    &.black {
      stroke: theme($colors, 'black');
    }

    &.white {
      stroke: theme($colors, 'white');
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
