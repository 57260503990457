@import 'styles/theme';

.CloseIcon {
  &.light {
    path {
      stroke: theme($colors, 'white');
    }
  }
}

@import 'styles/theme';

.SearchIcon {
  &.light {
    circle {
      stroke: theme($colors, 'white');
    }
    path {
      fill: theme($colors, 'white');
    }
  }
}

.AccountIcon {
  &.light {
    path,
    circle {
      stroke: theme($colors, 'white');
    }
  }
}

.ArrowIcon {
  &.light {
    path {
      fill: theme($colors, 'white');
    }
  }
}

.BagIcon {
  &.light {
    path {
      stroke: theme($colors, 'white');
    }
  }
}

.HeartIcon {
  &.light {
    path {
      stroke: theme($colors, 'gray-68');
      fill: rgba(174, 174, 174, 0);
      transition: ease 0.15s fill;

      &:hover {
        fill: rgba(174, 174, 174, 1);
      }
    }

    &.active {
      path {
        fill: rgba(174, 174, 174, 1);
      }
    }
  }
}

.MinusIcon,
.PlusIcon {
  width: 9px;
  height: 9px;

  &.light {
    path {
      fill: theme($colors, 'white');
    }
  }
}

.ChevronIcon {
  width: 10px;
}
