@import 'styles/theme';

.DesktopNavigation {
  position: relative;
  transition: ease 0.2s transform;

  .fixedHeader {
    z-index: 10;
    will-change: transform;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
    background-color: theme($colors, 'white');
    opacity: 0;
    transition: opacity 0.15s linear;
    z-index: 1;

    &.headerLight {
      opacity: 1;
    }

    &.hasScrolled {
      opacity: 1;
    }
  }
}
