@use 'title.module';

.rich-text {
  font-family: theme($fontFamilies, 'GTSuperText');
  font-weight: theme($fontWeights, 'medium');

  :global {
    .quote {
      display: inline-block;
      padding-left: getGridColumnWidth(1, $mobile);
      border-left: 1px solid theme($colors, 'black');
      font-size: theme($fontSizes, 16);

      @include from('md') {
        font-size: theme($fontSizes, 18);
        padding-left: theme($spacings, 60);
      }
    }

    .gold {
      display: block;
      color: theme($colors, 'gold');
      margin-top: theme($spacings, 30);
      margin-bottom: theme($spacings, 20);
      text-transform: uppercase;
      font-family: theme($fontFamilies, 'Graphik');
      font-weight: theme($fontWeights, 'medium');
      font-size: theme($fontSizes, 9);
      letter-spacing: 2px;

      @include from('md') {
        font-size: theme($fontSizes, 10);
      }

      @include from('md') {
        margin-top: theme($spacings, 50);
      }
    }
  }

  &-variant-1 {
    @extend .rich-text;
    p {
      text-transform: none;
      font-size: theme($fontSizes, 14);
      line-height: 1.78;
    }
  }

  &-variant-2 {
    @extend .rich-text;
    p {
      font-size: theme($fontSizes, 16);
      letter-spacing: 0;
      line-height: 1.875;
    }
  }

  &-variant-3 {
    @extend .rich-text;
    p {
      font-size: theme($fontSizes, 12);
      letter-spacing: 0;
      line-height: 1.67;

      @include from('md') {
        font-size: theme($fontSizes, 16);
        line-height: 1.875;
      }
    }
  }

  &-variant-4 {
    @extend .rich-text;
    font-size: theme($fontSizes, 12);
    line-height: 1.67;

    @include from('md') {
      font-size: theme($fontSizes, 14);
      line-height: 1.79;
    }

    h1,
    h2,
    h3 {
      @extend .title-18-13;
      margin-top: theme($spacings, 30);
      margin-bottom: theme($spacings, 20);

      @include from('md') {
        margin-top: theme($spacings, 40);
      }
    }

    ol,
    ul {
      list-style: inside;
      margin-top: theme($spacings, 30);
      margin-bottom: theme($spacings, 30);
      @include applyColumnWidth('padding-left', 2, $mobile);

      @include from('md') {
        @include applyColumnWidth('padding-left', 1, $desktop);
      }

      li {
        margin-top: theme($spacings, 5);
        margin-bottom: theme($spacings, 5);
      }
    }

    a {
      color: theme($colors, 'gold');
      text-decoration: underline;
    }
  }

  &-variant-5 {
    @extend .rich-text;
    font-size: theme($fontSizes, 12);

    a {
      font-family: theme($fontFamilies, 'Graphik');
      font-weight: theme($fontWeights, 'regular');
      font-size: theme($fontSizes, 11);
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }

    @include from('md') {
      font-size: theme($fontSizes, 14);
    }
  }

  &-variant-6 {
    @extend .rich-text;
    @extend .rich-text-variant-4;

    a,
    li,
    p {
      color: theme($colors, 'gray-47');
    }
  }

  &-variant-7 {
    @extend .rich-text;
    @extend .rich-text-variant-4;

    li,
    p {
      color: theme($colors, 'gray-47');
    }

    a {
      color: theme($colors, 'gray-20');
    }
  }

  &-variant-4,
  &-variant-6 {
    > :first-child {
      margin-top: theme($spacings, 15);
    }
  }
}
