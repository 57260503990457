@import 'styles/theme';

.NavigationLogo {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: theme($spacings, 63);
    height: 100%;
    position: relative;
    padding: 0;
  }
}
