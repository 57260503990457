@import 'styles/theme';

.base-grid {
  display: grid;
  width: 100vw;
  max-width: 100%;
  padding: 0 theme($mobile, 'gutter');
  grid-template-columns: repeat(theme($mobile, 'columns'), 1fr);
  column-gap: theme($mobile, 'gap');
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @include from('md') {
    width: vw(100);
    padding: 0 theme($desktop, 'gutter');
    grid-template-columns: repeat(theme($desktop, 'columns'), 1fr);
    column-gap: theme($desktop, 'gap');
  }

  @include from('max-grid-width') {
    width: theme($max, 'width');
    max-width: theme($max, 'width');
  }
}

.panel-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: theme($spacings, 15);
  padding: 0;

  @include from('md') {
    column-gap: theme($spacings, 30);
  }
}

.configurator-grid {
  @extend .base-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: theme($spacings, 30);
  padding: 0;

  @include from('md') {
    padding: 0 theme($spacings, 20);
  }

  @include from('l') {
    padding: 0 theme($desktop, 'gutter');
  }

  @include from('max-grid-width') {
    width: 100%;
  }
}

.account-grid {
  @extend .base-grid;

  @include from('md') {
    grid-template-columns: repeat(15, 1fr);
  }
}
