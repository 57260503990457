@import 'styles/theme';

.Alert {
  position: relative;
  background-color: theme($colors, 'white');
  box-sizing: border-box;
  padding: theme($spacings, 30) theme($spacings, 40);
  width: 300px;
  user-select: none;

  &.success,
  &.error {
    background-color: theme($colors, 'white');
    border: 1px solid theme($colors, 'gray-88');
  }

  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .closeIcon {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    padding: theme($spacings, 10);
    margin: calc(#{theme($spacings, 10)} * -1);
    width: 10px;
    height: 10px;
  }

  .title {
    line-height: 1;
  }

  .title,
  .message {
    margin-bottom: theme($spacings, 16);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
