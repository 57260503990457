@import 'styles/theme';

/**
* Class name format:
* --> -{mobile-size}-{desktop-size}
*/
.title {
  font-family: theme($fontFamilies, 'GTSuperText');
  font-weight: theme($fontWeights, 'medium');

  // // Custom label
  // :global {
  //   .outline {
  //     -webkit-text-fill-color: transparent;
  //     -webkit-text-stroke-width: 1px;
  //   }
  // }
  &-9-10 {
    @extend .title;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 9);
    letter-spacing: 2px;
    text-transform: uppercase;

    @include from('md') {
      font-size: theme($fontSizes, 10);
    }
  }

  &-9-11 {
    @extend .title;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 9);
    letter-spacing: 2px;
    text-transform: uppercase;

    @include from('md') {
      font-size: theme($fontSizes, 11);
    }
  }

  &-10 {
    @extend .title;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 10);
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &-11 {
    @extend .title;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 11);
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &-12 {
    @extend .title;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 12);
    letter-spacing: 1px;
  }

  &-13-20 {
    @extend .title;
    font-size: theme($fontSizes, 13);

    @include from('md') {
      font-size: theme($fontSizes, 20);
    }
  }

  &-14 {
    @extend .title;
    font-family: theme($fontFamilies, 'Graphik');
    font-size: theme($fontSizes, 14);
  }

  &-16 {
    @extend .title;
    font-size: theme($fontSizes, 16);
  }

  &-16-30 {
    @extend .title;
    font-size: theme($fontSizes, 16);

    @include from('md') {
      font-size: theme($fontSizes, 30);
    }
  }

  &-16-36 {
    @extend .title;
    font-size: theme($fontSizes, 16);

    @include from('md') {
      font-size: theme($fontSizes, 36);
    }
  }

  &-18 {
    @extend .title;
    font-size: theme($fontSizes, 18);
    line-height: 1.67;
  }

  &-18-13 {
    @extend .title;
    font-size: theme($fontSizes, 13);

    @include from('md') {
      font-size: theme($fontSizes, 18);
    }
  }

  &-20-30 {
    @extend .title;
    font-size: theme($fontSizes, 20);

    @include from('md') {
      font-size: theme($fontSizes, 30);
    }
  }

  &-20-36 {
    @extend .title;
    font-size: theme($fontSizes, 20);

    @include from('md') {
      font-size: theme($fontSizes, 36);
    }
  }

  &-fluid-16-24 {
    @extend .title;

    @include fluidType(
      theme($screens, 'md'),
      theme($max, 'width-design'),
      theme($fluidFontSizes, 16),
      theme($fluidFontSizes, 24)
    );

    @include scaledFontSize(theme($fluidFontSizes, 24));
  }

  &-fluid-16-30 {
    @extend .title;

    @include fluidType(
      theme($screens, 'md'),
      theme($max, 'width-design'),
      theme($fluidFontSizes, 16),
      theme($fluidFontSizes, 30)
    );

    @include scaledFontSize(theme($fluidFontSizes, 30));
  }

  // &-fluid-70-45 {
  //   @extend .title;
  //   font-size: theme($fontSizes, 70);

  //   @include from('md') {
  //     @include fluidType(
  //       theme($screens, 'md'),
  //       theme($max, 'width-design'),
  //       theme($fontSizes, 45),
  //       theme($fontSizes, 70)
  //     );
  //   }
  //
  //   @include scaledFontSize(theme($fluidFontSizes, 70));
  // }
}
