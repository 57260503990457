@import 'styles/theme';

$headerHeight: theme($navigationHeight, 'mobile');
$rowsWidth: 59.03vw;

.Panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: vh(100);
  top: 0;
  z-index: 20;
  overflow: hidden;
  transform: translateX(-100%);
  background-color: theme($colors, 'gray-96');

  @include from('md') {
    width: vw(100);
  }
}

.headerMask {
  position: relative;
  z-index: 20;
}

.content {
  position: relative;
  width: 100%;
  height: 100vh;
  height: vh(100);
  padding: {
    bottom: theme($spacings, 80);
    left: theme($mobile, 'gutter');
    right: theme($mobile, 'gutter');
  }
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: -1;

  &::-webkit-scrollbar {
    display: none;
  }

  @include from('md') {
    padding: {
      left: theme($desktop, 'gutter');
      right: theme($desktop, 'gutter');
    }
  }
}

.row {
  max-width: 100%;
  margin-left: auto;
  @include setPropertyValue(
    (
      'values': $mobile,
      'propertyName': 'width',
      'gutters': 1,
      'gaps': 1,
      'columns': 7,
    )
  );

  &:not(:last-child) {
    margin-bottom: theme($spacings, 100);
  }
}

.sectionTitle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: theme($spacings, 15) 0;
  overflow: hidden;
  border-top: solid 1px theme($colors, 'gray-20');

  .link {
    letter-spacing: 3px;
    line-height: 30px;
  }
}

.directLink {
  text-align: left;
  width: 100%;
  display: block;
  overflow: hidden;
  border-top: solid 1px theme($colors, 'gray-20');
}
